import React from 'react'
import { Field, Formik } from 'formik'
import { Button, Checkbox, Fade, Flex, Stack, Text, useBoolean, useToast } from '@chakra-ui/react'
import { InputForm } from 'components/InputForm'
import * as Yup from 'yup'
import { useState } from 'react'
import { PolicyModal } from './PolicyModal'
import { PasswordCheck } from 'components/PasswordCheck/PasswordCheck'
import { useYaMetric } from '../../hooks/useYaMetric'
import { residentOptions, residentType } from '../../constants/residentType'
import { InputPasswordForm } from '../InputPasswordForm'
import { Select } from '../Select'
import { CompanyDaData } from '../CompanyDaData'
import { useHistory } from 'react-router'
import { api } from '../../api'

const formSchema = Yup.object().shape({
  contragentName: Yup.string().required('Заполните поле'),
  email: Yup.string().required('Заполните поле'),
  password: Yup.string().required('Заполните поле'),
  inn: Yup.string().min(9, 'Минимум 9 цифр').required('Заполните поле')
})

export const RegistrationForm = () => {
  const toast = useToast()
  const { yaClick } = useYaMetric()
  const history = useHistory()
  const [companySelected, setCompanySelected] = useState(null)
  const [resident, setResident] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleForm = async (values, {setSubmitting, resetForm}) => {
    try {
      yaClick('registration')
      setIsLoading(true)
      if (resident === residentType.russianFederation) {
        await api.accounts.signUpExtend({
          ...values,
          isLegal: companySelected.isLegal
        })
      } else {
        await api.accounts.signUp(values)
      }

      toast({
        title: 'Готово',
        description: 'Мы отправили письмо для подтверждения на почту',
        status: 'success'
      })

      history.push('/equipments')
    } catch (error) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelect = (e) => {
    setResident(e.value)
    setCompanySelected(null)
  }

  return (
    <>
      <Fade in>
        <Stack spacing={6}>
          <Stack>
            <Text fontSize="1.1rem" fontWeight="bold">Шаг 1</Text>
            <Select
              placeholder={'Выберите резидента'}
              isDisabled={isLoading}
              options={residentOptions}
              value={resident}
              onChange={handleSelect}
            />
          </Stack>
          <Stack>
            {resident === residentType.russianFederation && (
              <>
                <Text fontSize="1.1rem" fontWeight="bold">Шаг 2</Text>
                {!companySelected ? (
                  <CompanyDaData onChange={setCompanySelected}/>
                ) : (
                  <Stack spacing={3}>
                    <Text fontSize="0.84rem" lineHeight="1.3">Выбранная компания: {companySelected?.name}</Text>
                    <FieldsRegistration
                      isLoading={isLoading}
                      onSubmit={handleForm}
                      initialValues={{
                        inn: companySelected?.inn,
                        email: '',
                        contragentName: companySelected?.name,
                        password: '',
                        kpp: companySelected?.kpp,
                      }}
                    />
                  </Stack>
                )}
              </>
            )}
            {(resident === residentType.belarus || resident === residentType.undefined) && (
              <Stack>
                <Text fontSize="1.1rem" fontWeight="bold">Шаг 2</Text>
                <FieldsRegistration
                  isLoading={isLoading}
                  onSubmit={handleForm}
                  initialValues={{
                    inn: '',
                    email: '',
                    contragentName: '',
                    password: ''
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Fade>

    </>
  )
}

const FieldsRegistration = ({ isLoading, initialValues, onSubmit }) => {
  const [isActiveSubmit, setIsActiveSubmit] = useState(false)
  const [isOpenPolicy, setIsOpenPolicy] = useBoolean()

  const handlePolicyClick = (e) => {
    e.preventDefault()
    setIsOpenPolicy.on()
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={formSchema}
        initialValues={initialValues}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Stack spacing={3}>
              <Field
                name="inn"
                label={props.values?.resident === residentType.belarus ? 'УНП' : 'ИНН'}
                component={InputForm}
                isDisabled={isLoading || !!initialValues?.inn}
              />
              <Field
                name="contragentName"
                label="Наименование компании или ИП"
                component={InputForm}
                isDisabled={isLoading || !!initialValues?.contragentName}
              />
              <Field
                name="email"
                label="Электронная почта"
                component={InputForm}
                isDisabled={isLoading}
              />
              <Field
                name="password"
                label="Пароль"
                component={InputPasswordForm}
                isDisabled={isLoading}
              />
              <PasswordCheck
                value={props.values.password}
              />
              <Flex justifyContent="space-between" alignItems="center" py="10px">
                <Checkbox isChecked={isActiveSubmit} onChange={e => setIsActiveSubmit(e.target.checked)}>
                  <Text fontSize="0.84rem" lineHeight="1.3">
                    Я ознакомлен с условиями пользовательского соглашения и даю согласие на <Text as="span" onClick={handlePolicyClick} color="blue.400">обработку моих персональных данных</Text>
                  </Text>
                </Checkbox>
              </Flex>
              <Button
                type="submit"
                isLoading={isLoading}
                colorScheme="blue"
                isDisabled={!isActiveSubmit}
              >
                Зарегистрироваться
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
      <PolicyModal
        isOpen={isOpenPolicy}
        onClose={() => setIsOpenPolicy.off()}
      />
    </>
  )
}
